exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-languages-json-language-404-tsx": () => import("./../../../src/pages/{languagesJson.language}/404.tsx" /* webpackChunkName: "component---src-pages-languages-json-language-404-tsx" */),
  "component---src-pages-languages-json-language-about-tsx": () => import("./../../../src/pages/{languagesJson.language}/about.tsx" /* webpackChunkName: "component---src-pages-languages-json-language-about-tsx" */),
  "component---src-pages-languages-json-language-contact-tsx": () => import("./../../../src/pages/{languagesJson.language}/contact.tsx" /* webpackChunkName: "component---src-pages-languages-json-language-contact-tsx" */),
  "component---src-pages-languages-json-language-index-tsx": () => import("./../../../src/pages/{languagesJson.language}/index.tsx" /* webpackChunkName: "component---src-pages-languages-json-language-index-tsx" */),
  "component---src-pages-languages-json-language-legal-tsx": () => import("./../../../src/pages/{languagesJson.language}/legal.tsx" /* webpackChunkName: "component---src-pages-languages-json-language-legal-tsx" */),
  "component---src-pages-languages-json-language-portfolio-tsx": () => import("./../../../src/pages/{languagesJson.language}/portfolio.tsx" /* webpackChunkName: "component---src-pages-languages-json-language-portfolio-tsx" */),
  "component---src-pages-languages-json-language-privacy-tsx": () => import("./../../../src/pages/{languagesJson.language}/privacy.tsx" /* webpackChunkName: "component---src-pages-languages-json-language-privacy-tsx" */),
  "component---src-pages-languages-json-language-refund-tsx": () => import("./../../../src/pages/{languagesJson.language}/refund.tsx" /* webpackChunkName: "component---src-pages-languages-json-language-refund-tsx" */),
  "component---src-pages-languages-json-language-skill-tsx": () => import("./../../../src/pages/{languagesJson.language}/skill.tsx" /* webpackChunkName: "component---src-pages-languages-json-language-skill-tsx" */),
  "component---src-pages-languages-json-language-term-tsx": () => import("./../../../src/pages/{languagesJson.language}/term.tsx" /* webpackChunkName: "component---src-pages-languages-json-language-term-tsx" */),
  "component---src-pages-languages-json-language-work-tsx": () => import("./../../../src/pages/{languagesJson.language}/work.tsx" /* webpackChunkName: "component---src-pages-languages-json-language-work-tsx" */)
}

