import React from "react";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import SabaSVG from "~/assets/svg/saba"
import { MobileNavbar } from "./mobilenav";
const NavList = styled.div`
    background: #1b1a26;
    padding: 0;
    border-radius: 0.2rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
const NavItemT = styled.div`
    list-style: none;
    margin-right: 2rem;
    color: white;
    :first-child {
        margin-right: auto;
        margin-left: 2rem;
    }  
`;
const NavItem = styled(NavItemT)`
    @media(max-width: 900px) {
        display: none;
    }
`;

const LinkItem = styled(Link)`
    color: #b8b5b5;
    :hover {
        color: #039d69;
    }
    text-decoration: none;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 1.2em;
`;

const Desktop = styled.div`
    @media(max-width: 900px) {
        display: none;
    }
`;

const Mobile = styled.div`
    @media(min-width: 900px) {
        display: none;
    }
`;

export const query = graphql`
    fragment LayoutStringsFragment on Strings {
        contact
        about
        profile
    }
`;


export const Navbar: React.FC = () => {
    return (
        <>
            <nav>
                <NavList>
                <NavItemT>
                    <LinkItem to ="/">
                        <SabaSVG/>
                    </LinkItem>
                </NavItemT>
               <Mobile><MobileNavbar/></Mobile>
                <NavItem>
                    <LinkItem to ="/about">About</LinkItem>
                </NavItem>
                <NavItem>
                    <LinkItem to ="/work">Work</LinkItem>
                </NavItem>
                <NavItem>
                    <LinkItem to ="/skill">Skills</LinkItem>
                </NavItem>
                <NavItem>
                    <LinkItem to ="/portfolio">Portfolio</LinkItem>
                </NavItem>
                <NavItem>
                    <LinkItem to ="/contact">Contact</LinkItem>
                </NavItem>
                </NavList>
            </nav>
        </>
    );
};