import React from "react";
import styled from "styled-components";

const StyledMenu = styled.nav<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #17142b;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  height: 100vh;
  width: 70vw;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 100;
  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: #f7f7f7;
    text-decoration: none;
    transition: color 0.3s linear;
    cursor: pointer;
    @media(max-width: 600px) {
        font-size: 1.5rem;
    }
    &:hover {
      color: #a9a6f1aa;
    }
  }
`

const Menu = ({ open }) => {
    return (
        <StyledMenu open={open}>
            <a href="/">
                <span role="img" aria-label="home">🛖</span>
                Home
            </a>
            <a href="/about">
                <span role="img" aria-label="about us">💁🏻‍♂️</span>
                About
            </a>
            <a href="/work">
                <span role="img" aria-label="work">💻</span>
                Work
            </a>
            <a href="/skill">
                <span role="img" aria-label="skill">🥷🏿</span>
                Skills
            </a>
            <a href="/portfolio">
                <span role="img" aria-label="portfolio">💼</span>
                Portfolio
            </a>
            <a href="/contact">
                <span role="img" aria-label="contact">📩</span>
                Contact
            </a>
        </StyledMenu>
    )
}

const StyledBurger = styled.button<{ open: boolean }>`
  position: absolute;
  top: 1.5rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({ open }) => open ? '#ffffff' : '#EFFFFA'};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`

const Burger = ({ open, setOpen }) => {
    return (
        <StyledBurger open={open} onClick={() => setOpen(!open)}>
            <div />
            <div />
            <div />
        </StyledBurger>
    )
}


export const MobileNavbar = () => {
    const [open, setOpen] = React.useState(false);
    const node = React.useRef();
    return (
        <div>
            <div ref={node}>
                <Burger open={open} setOpen={setOpen} />
                <Menu open={open} />
            </div>
        </div>
    )
}


const useOnClickOutside = (ref, handler) => {
    React.useEffect(() => {
        const listener = event => {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler(event);
        };
        document.addEventListener('mousedown', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
        };
    },
        [ref, handler],
    );
};