
import React from "react";
import styled from "styled-components";
import { graphql, PageProps } from "gatsby";
import { BiCoffeeTogo } from "react-icons/bi";

const Buttona = styled.a`
    padding: .5rem;
    font-size: 1rem;
    color: #ffffff;
    background-color:  #9b7b67;
    border-radius: 2px;
    text-decoration: none;
    text-align: center;
    transition: all 1s ease;
    :hover {
      background-color:  #4c2e1b;
    }
`;

const Container = styled.footer`
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-family: "Roboto";
    
    @media(max-width: 900px) {
      justify-content: center;
      gap: 2rem;
      border-top: 1px solid #797985;
      padding-top: 1rem;
      flex-direction: column;
    }
    a { 
        text-decoration: none;
        color: #fff;
    }
    .tooltip {
  position: relative;
  display: inline-block;
}
    .tooltip .tooltiptext {
  visibility: hidden;
  width: 100%;
  background-color: #303030;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  text-decoration: none;
  padding: 1rem;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -8rem;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
`;
const CopyRight = styled.p`
    color: white;
`;

export const Footer: React.FC = () => {
    return (
        <>
        <Container>
            <div className="tooltip">
                <a rel="license" href="http://creativecommons.org/licenses/by-nc-sa/4.0/">
                <img alt="Creative Commons License" style={{borderWidth: "0"}} src="https://i.creativecommons.org/l/by-nc-sa/4.0/88x31.png" /></a>
                <span className="tooltiptext">This work is licensed under a <a rel="license" href="http://creativecommons.org/licenses/by-nc-sa/4.0/">Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International License</a>.</span>
                </div>
                <CopyRight>© 2022 Saba Hossein</CopyRight>
                <div>
                  <a href="/term" >Term </a>
                <a href="/legal">| Legal |</a>
                <a href="/privacy"> Privacy |</a>
                <a href="https://merchant.razorpay.com/policy/KkCtBVmV7238OY/shipping">Shipping and Delivery | </a>
                <a href="/refund"> Refund, Return and Cancellation</a>
                </div>
                
                <Buttona href="https://rzp.io/l/2M9xD4Ks" target="_blank">Buy Me a Coffee<BiCoffeeTogo style={{padding: "0 .5rem"}}/></Buttona>   
        </Container>
             </>
    );
};
