import React from "react";
import styled from "styled-components";
import { graphql, PageProps } from "gatsby";
import { Navbar } from "./navbar";
import { Footer } from "./footer";
import { MobileNavbar } from "./mobilenav";

const LayoutStyle = styled.div`
    height: 100%;
    width: 100%;
`;


export const query = graphql`
    fragment LayoutStringsFragment on Strings {
        contact
        about
        profile
    }
`;


export const Layout: React.FC<PageProps> = ({ children }) => {
    return (
        <>
            <Navbar/>
            <LayoutStyle>
                {children}
            </LayoutStyle>
            <Footer />
        </>
    );
};