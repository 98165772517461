import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
export const GlobalStyle = createGlobalStyle`
    ${reset}
    html {
      font-size: 16px;
      scroll-behavior: smooth;
    }
    body {
       -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        margin: 0;
        background-color: #000118;
        color: #fff;
    }
`;
